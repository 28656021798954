import { Lock } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export const PrivateScreen = () => {
  const [t] = useTranslation('landingScreen', { keyPrefix: 'privateScreen' })
  return (
    <div className="mt-9 flex flex-col items-center gap-8 px-5 md:px-0">
      <div className="flex items-center gap-2">
        <Lock size={36} />
        <h4 className="text-center text-3xl md:text-5xl">{t('title')}</h4>
      </div>
      <p className="w-full max-w-[800px] text-center text-gray-800 md:text-lg">{t('desc')}</p>
    </div>
  )
}
