'use client'

import { BorderBeam, Button } from '@my/magic-ui'
import { ArrowRightIcon } from '@radix-ui/react-icons'
import { getExplorePostLink } from 'app/utils/model-version'
import { useInView } from 'framer-motion'
import { ChevronRight } from 'lucide-react'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
const underlinedWord = (text: string) => (
  <span className="cursor-pointer underline decoration-yellow-300 decoration-wavy"> {text}</span>
)

export default function HeroSection() {
  const { t } = useTranslation('landingScreen')
  const ref = useRef(null)
  const inView = useInView(ref, { once: true, margin: '-100px' })
  const router = useRouter()

  const onVoiceIntegrationClick = () => {
    router.push('/generate')
  }

  return (
    <section id="hero" className="relative mx-auto mt-32 max-w-[80rem] px-6 text-center md:px-8">
      <div onClick={onVoiceIntegrationClick}>
        <p className="inline-flex w-auto cursor-pointer items-center justify-center gap-2 rounded-3xl border border-solid border-white/20 bg-gradient-to-r from-white/5 to-white/10 px-5 py-2.5 text-xs font-medium tracking-wide text-white/90 transition-all duration-300 hover:border-white/30 hover:bg-white/10 hover:shadow-lg hover:shadow-white/5 md:text-sm">
          <span className="animate-pulse">💥</span>
          Voice integration released!
          <ChevronRight
            size={16}
            className="text-white/90 transition-transform duration-300 group-hover:translate-x-0.5"
          />
        </p>
      </div>
      <div className="animate-fade-in flex items-center justify-between py-6 opacity-0 [--animation-delay:200ms]">
        <h1 className="f-1 font-grotesque translate-y-[-1rem] text-balance bg-gradient-to-br from-black from-30% to-black/40 bg-clip-text text-left text-4xl font-[700] leading-none tracking-tighter text-transparent sm:text-5xl md:text-7xl xl:text-8xl dark:from-white dark:to-white/40">
          MyDreamBoy AI Porn Generator <br className="hidden md:block" />
          for Male.
        </h1>
        <img
          src="https://api.mydreamboy.com/storage/v1/object/public/static/web_hero_model.png"
          className="hidden w-1/2 lg:block"
        />
        <img
          src="https://api.mydreamboy.com/storage/v1/object/public/static/mobile_hero_model.png"
          className="-ml-10 block w-1/2 shrink-0 lg:hidden"
        />
      </div>
      <p className="animate-fade-in mb-12 translate-y-[-1rem] text-balance text-lg tracking-tight text-gray-400 opacity-0 [--animation-delay:400ms] md:text-xl">
        {t('heroDesc.text1')} {underlinedWord(t('heroDesc.keyword1'))},
        <br className="hidden md:block" /> {t('heroDesc.text2')}{' '}
        {underlinedWord(t('heroDesc.keyword2'))}, {t('heroDesc.text3')}{' '}
        {underlinedWord(t('heroDesc.keyword3'))}.
      </p>
      <Button
        size="lg"
        className="animate-fade-in translate-y-[-1rem] gap-1 rounded-lg bg-gray-900 text-white opacity-0 ease-in-out [--animation-delay:600ms] dark:text-black"
        onClick={() => router.push(getExplorePostLink())}
      >
        <span>{t('start')} </span>
        <ArrowRightIcon className="ml-1 size-4 transition-transform duration-300 ease-in-out group-hover:translate-x-1" />
      </Button>
      <div
        ref={ref}
        className="animate-fade-up relative mt-[8rem] opacity-0 [--animation-delay:400ms] [perspective:2000px] after:absolute after:inset-0 after:z-50 after:[background:linear-gradient(to_top,black_30%,transparent)]"
      >
        <div
          className={`rounded-xl border border-white/10 bg-white bg-opacity-[0.01] before:absolute before:bottom-1/2 before:left-0 before:top-0 before:h-full before:w-full before:opacity-0 before:[background-image:linear-gradient(to_bottom,var(--color-one),var(--color-one),transparent_40%)] before:[filter:blur(180px)] ${
            inView ? 'before:animate-image-glow' : ''
          }`}
        >
          <BorderBeam
            size={200}
            duration={12}
            delay={11}
            colorFrom="var(--color-one)"
            colorTo="var(--color-two)"
          />

          <img
            src="https://api.mydreamboy.com/storage/v1/object/public/static/new_hero.png"
            alt="Hero Image"
            className="relative h-full w-full rounded-[inherit] border object-contain"
          />
        </div>
      </div>
    </section>
  )
}
